import React from 'react';

import { Button, Container, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system'

const FormControlContainer = styled(Container)({
    paddingTop: '10px'
})

export default function ListFilter({ open, handleClose }: { open: boolean, handleClose: any }) {
    return <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>List filter</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
            <FormControlContainer>
                <FormControl fullWidth>
                    <InputLabel id="order_label">Order</InputLabel>

                    <Select
                        labelId="order_label"
                        id="order"
                        label="Order"
                        defaultValue="name"
                    >
                        <MenuItem value="name">Name</MenuItem>
                        <MenuItem value="updated">Updated</MenuItem>
                    </Select>
                </FormControl>            
            </FormControlContainer>

            <FormControlContainer>
                <FormControl fullWidth>
                    <InputLabel id="filter_label">Filter</InputLabel>

                    <Select
                        labelId="filter_label"
                        id="filter"
                        label="Filter"
                        defaultValue="all"
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="only_unchecked">Only unchecked</MenuItem>
                        <MenuItem value="modified_in_last_hour">Modified in last hour</MenuItem>
                        <MenuItem value="modified today">Modified today</MenuItem>
                    </Select>
                </FormControl>
            </FormControlContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
    </Dialog>
}