export function sanitize(text: string): string {
    if (text === null) {
        return text
    }

    return text
        .trim()
        .toUpperCase()
        .replace('Ę', 'E')
        .replace('Ó', 'O')
        .replace('Ą', 'A')
        .replace('Ś', 'S')
        .replace('Ł', 'L')
        .replace('Ż', 'Z')
        .replace('Ź', 'Z')
        .replace('Ć', 'C')
        .replace('Ń', 'N')
}