import { List } from './models/list'

export class ListsRepository {
    private _key: string | null;
    private _baseUrl: string;

    constructor (key: string | null, baseUrl: string) {
        this._key = key
        this._baseUrl = baseUrl
    }

    setKey(key: string | null) {
        this._key = key
    }

    getAllListsCached(): List[] {
        console.log('Getting all lists cached')

        const lists = localStorage.getItem('market-lists')

        if (lists != null) {
            return JSON.parse(lists)
        }

        return [];
    }

    async getAllLists(): Promise<List[]> {
        console.log('Getting all lists')

        const data = await fetch(`${this._baseUrl}/lists/`, {
            headers: {
                'authorization': `bearer ${this._key}`
            }
        })

        if (data.ok) {
            const json = await data.json();

            var content = json.data as List[]
            content.sort((a: List, b: List) => a.name.localeCompare(b.name))

            localStorage.setItem('market-lists', JSON.stringify(content))

            return content
        }

        throw new Error(data.status.toString())
    }

    getListByIdCached(id: string): List | null {
        const cachedLists = this.getAllListsCached()

        if (cachedLists != null) {
            const matched = cachedLists.filter(l => l.id === id)[0] as List
            return matched;
        }

        return null;
    }

    async getListById(id: string): Promise<List> {
        console.log('Getting single list')

        let lists = this.getAllListsCached();

        console.log('Cached lists', lists);

        if (lists === null) {
            lists = await this.getAllLists()
        }

        return lists.filter(l => l.id.toString() === id)[0] as List
    }
}