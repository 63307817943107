import { useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom";

import DataContext from '../contexts/DataContexts'

export default function Logout({ onLogout }: { onLogout: () => void }) {
    const repository = useContext(DataContext)
    const navigate = useNavigate();
    
    useEffect(() => {
        repository.logout()  
        onLogout();
        setTimeout(() => navigate('/'), 500)
    }, [navigate, repository, onLogout])

    return <>Logging out...</>
}