import { createContext, useReducer, useContext } from 'react';

const LoadingContext = createContext(false);
const LoadingDispatchContext = createContext(null as unknown);

export function LoadingProvider({ children }: { children: any }) {
    const [isLoading, dispatch] = useReducer(loadingReducer, false );
  
    return (
      <LoadingContext.Provider value={isLoading}>
        <LoadingDispatchContext.Provider value={dispatch}>
          {children}
        </LoadingDispatchContext.Provider>
      </LoadingContext.Provider>
    );
}
  
export function useLoading() {
    return useContext(LoadingContext);
}

export function useLoadingDispatch() {
    return useContext(LoadingDispatchContext);
}

function loadingReducer(isLoading: boolean, action: any) {
    return action.isLoading;    
}