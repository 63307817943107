import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ListItem } from '../repositories/models/listItem';
import TextField from '@mui/material/TextField';

export default function ItemDialog({ open, item, onSave, onDelete, onCancel }: { open: boolean, item: ListItem | undefined, onSave: (id: string, name: string) => void, onDelete: () => void, onCancel: () => void}) {
  const [text, setText] = useState('...')

  useEffect(() => {
    setText(item?.name || '')
  }, [item])

  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='sm'
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
            {item?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField id="standard-basic" fullWidth label="Item" variant="standard" onChange={ e => setText(e.target.value) } value={text} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onSave(item!.id, text)} color='success'>Save</Button>
          <Button onClick={onDelete} color='error'>Delete</Button>
          <Button onClick={onCancel} autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}