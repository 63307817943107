import LinearProgress from '@mui/material/LinearProgress';
import { useLoading } from '../contexts/LoadingContext';

export default function Loader() {
    const loading = useLoading();

    if (loading) {
        return <LinearProgress style={{ marginTop: '10px' }}/>
    }

    return <></>;
}