import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CachedIcon from '@mui/icons-material/Cached';
import ReorderIcon from '@mui/icons-material/Reorder';

const drawerWidth = 240;

function DrawerAppBar({ window, title, openFilter, requestRefresh }: { window: any, title: string, openFilter: () => void, requestRefresh: () => void }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src="./favicon-32x32.png" alt='Market'/>

      <Typography variant="h6" sx={{ my: 2 }} style={{ marginLeft: '10px' }}>
         {title}               
      </Typography>
      
      <Divider />
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <img src="./favicon-32x32.png" alt='Market'/>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
            style={{ marginLeft: '10px' }}
          >
             {title}
          </Typography>

          <IconButton onClick={c => navigate('/')}>
            <ReorderIcon />
          </IconButton>

          <IconButton onClick={c => requestRefresh()}>
            <CachedIcon />
          </IconButton>

          <IconButton onClick={c => openFilter()}>
            <FilterAltIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>      
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;