import React, { useState, useCallback } from 'react';

import { LinearProgress, IconButton, Paper, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';

import { ListItem } from '../repositories/models/listItem';

export default function ListItemRow({item, isLoading, onEdit, onChecked} : { item: ListItem, isLoading: boolean, onEdit: (item: ListItem) => void, onChecked: (item: ListItem, isChecked: boolean) => void }) {
    const [checked, setChecked] = useState(item.isChecked)
    
    const setItemChecked = useCallback((ch: boolean) => {
        setChecked(ch)
        onChecked(item, ch)
    }, [item, onChecked])

    return <Paper style={{ padding: '3px', margin: '3px' }}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} sm={1}>
                { !isLoading && <Checkbox checked={checked} onClick={e => setItemChecked(!checked)} /> }
            </Grid>
        
            <Grid item xs={8} sm={10}>
                <Typography >
                    {item.name}
                </Typography>
            </Grid>

            <Grid item xs={2} sm={1} alignContent='end' alignItems='end' style={{ textAlign: 'right' }}>
                <IconButton color="primary" aria-label="edit" onClick={c => onEdit(item)}>
                    <EditIcon />
                </IconButton>
            </Grid>
        </Grid>

        {isLoading && <LinearProgress /> }
        {!isLoading && <div style={{ height: '2px' }}></div> }
    </Paper>
}