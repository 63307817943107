import { ListItem } from './models/listItem'
import { sanitize } from '../tools/string';

export default class ListItemsRepository {
    private _key: string | null;
    private _baseUrl: string;

    constructor (key: string | null, baseUrl: string) {
        this._key = key
        this._baseUrl = baseUrl
    }

    setKey(key: string | null) {
        this._key = key
    }

    async getItems(listId: string): Promise<ListItem[]> {
        let data:ListItem[] = [];

        const response = await fetch(`${this._baseUrl}/lists/${listId}/items`, {
            headers: {
                'Authorization': `Bearer ${this._key}`
            }
        })

        if (response.ok) {
            const json = await response.json();
            var jsonData = json.data;
            data = jsonData.map((d: any) => {
                const li = d as ListItem
                li.sortName = sanitize(li.name)
                return li;
            })

            return data
        }

        throw new Error(response.statusText)
    }

    async saveItem(listId: string, item: ListItem): Promise<ListItem> {
        const response = await fetch(`${this._baseUrl}/lists/${listId}/items`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this._key}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })

        if (!response.ok) {
            throw new Error(response.statusText)
        }

        const responseText = await response.json()
        item.id = responseText.data;

        if (item.id.length > 0) {
            if (item.id.startsWith("\"")) {
                item.id = item.id.substring(1)
            }

            if (item.id.endsWith("\"")) {
                item.id = item.id.substring(0, item.id.length - 1)
            }
        }

        console.log('New item id', item.id)

        return item
    }

    async deleteItem(listId: string, itemId: string): Promise<void> {
        const response = await fetch(`${this._baseUrl}/lists/${listId}/items/${itemId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this._key}`
            },
        })

        if (!response.ok) {
            throw new Error(response.statusText)
        }
    }

    async updateItem(listId: string, itemId: string, checked: boolean, name: string): Promise<void> {
        const response = await fetch(`${this._baseUrl}/lists/${listId}/items/${itemId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${this._key}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                isChecked: checked,
                name: name
            })
        })

        if (!response.ok) {
            throw new Error(response.statusText)
        }
    }
}