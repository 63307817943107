import React, { useState } from 'react';
import './App.css';
import 'react-notifications/lib/notifications.css';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HashRouter } from "react-router-dom";
import { Route, Routes } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Lists from './components/Lists'
import List from './components/List'
import { LoadingProvider } from './contexts/LoadingContext';
import Loader from './components/Loader'
import { NotificationContainer } from 'react-notifications';
import DataContext from './contexts/DataContexts';
import Repositories from './repositories/repositories';
import LoginDialog from './components/LoginDialog';
import Logout from './components/Logout'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const repositories = new Repositories()

function App() {
  const [isLogged, setIsLogged] = useState(repositories.isLogged())

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

        <LoadingProvider>
          <DataContext.Provider value={repositories}>
            {isLogged &&
              <Box component="main" sx={{ p: 3 }}>
                <Toolbar />

                <HashRouter>
                  <Routes>
                    <Route path='/' element={ <Lists /> }/>
                    <Route path='/list/:id' element={ <List /> }/>
                    <Route path='/logout' element={ <Logout onLogout={ () => setIsLogged(false) } /> }/>
                  </Routes>
                </HashRouter>

                <Loader />

                <footer>v0.7.0</footer>
              </Box>
            }

            {!isLogged && <LoginDialog open={true} handleClose={() => { setIsLogged(repositories.isLogged()) }} /> }

            <NotificationContainer/>
          </DataContext.Provider>
        </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;
