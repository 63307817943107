export default class LoginRepository {
    private _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl
    }

    async tryLogin(apiKey: string): Promise<boolean> {
        const valid = apiKey !== null && apiKey.length > 0;

        return new Promise<boolean>(resolve => {
            setTimeout(() => {
                resolve(valid);
            }, 500)
        })
    }
}