import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { useLoadingDispatch  } from '../contexts/LoadingContext'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { NotificationManager} from 'react-notifications';
import Grid from '@mui/material/Grid';

import MenuBar from './MenuBar'
import DataContext from '../contexts/DataContexts'
import { List } from '../repositories/models/list';

export default function Lists() {
    const repository = useContext(DataContext)
    const dispatchLoading = useLoadingDispatch() as ({ isLoading }: { isLoading: boolean }) => any;
    const [ lists, setLists ] = useState([] as List[])

    const fetch = async () => {
        dispatchLoading({ isLoading: true })

        try {
            var data = await repository.getLists().getAllLists();
            setLists(data);
        } catch (e: any) {
            console.error('Error on getting lists', e)
            NotificationManager.error(`${e}`, `Error on getting lists`)
        }
        finally {
            dispatchLoading({ isLoading: false })
        }
    }

    useEffect(() => {
        setLists(repository.getLists().getAllListsCached())
    }, [repository])

    return <>
        <MenuBar title='Market' openFilter={() => {}} requestRefresh={() => fetch()} />

        <Grid container>
            <Grid item xs={0} md={4}></Grid>
            <Grid item xs={12} md={4}>
                <Stack>
                    { lists.map(l => <Link key={l.id} to={`/list/${l.id}`} style={{ display: 'contents' }}><Button style={{ margin: '5px' }} variant='outlined'>{l.name}</Button></Link> ) }
                </Stack>
            </Grid>
            <Grid item xs={0} md={4}></Grid>
        </Grid>
    </>;
}