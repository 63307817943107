import ListItemsRepository from "./listItemsRepository";
import { ListsRepository } from "./listsRepository"
import LoginRepository from "./loginRepository";

export default class Repositories {
    private _lists: ListsRepository;
    private _listItems: ListItemsRepository
    private _loginRepository: LoginRepository
    private _isLogged: boolean

    constructor() {
        const key = localStorage.getItem('market-key')
        //const baseUrl = 'https://ymarket.azurewebsites.net/api'
        //const baseUrl = 'http://localhost:8080'
        const baseUrl = 'https://market.yaras.pl/api'

        this._lists = new ListsRepository(key, baseUrl)
        this._listItems = new ListItemsRepository(key, baseUrl)
        this._loginRepository = new LoginRepository(baseUrl)
        this._isLogged = key !== null
    }

    getLists(): ListsRepository {
        return this._lists;
    }

    getListItems(): ListItemsRepository {
        return this._listItems;
    }

    async tryLogin(apiKey: string): Promise<boolean> {
        this._isLogged = await this._loginRepository.tryLogin(apiKey);

        if (this._isLogged) {
            localStorage.setItem('market-key', apiKey)
            this._listItems.setKey(apiKey)
            this._lists.setKey(apiKey)
        } else {
            this.logout()
        }

        return this._isLogged
    }

    logout() {
        localStorage.removeItem('market-key')
        this._isLogged = false
        this._listItems.setKey(null)
        this._lists.setKey(null)
    }

    isLogged(): boolean {
        const key = localStorage.getItem('market-key')

        if (key === null || key.length === 0) {
            return false;
        }

        return this._isLogged;
    }
}