import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import MenuBar from './MenuBar'
import { useLoadingDispatch  } from '../contexts/LoadingContext'
import { Button, Paper, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import DataContext from '../contexts/DataContexts'
import { ListItem } from '../repositories/models/listItem';
import ListFilter from './ListFilter'
import ListItemRow from './ListItemRow';
import ItemDialog from './ItemDialog'
import { NotificationManager } from 'react-notifications';
import { v4 as uuidv4 } from 'uuid';
import { sanitize } from '../tools/string';

const minimumFilterLength = 1
const minimumFilterDelay = 200
let textEnteredTimeout: NodeJS.Timeout;

function sortList(data: ListItem[], filter: string | null): ListItem[] {
    if (data === null) {
        return data;
    }

    let dataCopy = data.slice();

    if (filter !== null && filter.length > minimumFilterLength) {
        dataCopy = dataCopy.filter(d => d.sortName.indexOf(filter as string) >= 0)
    }

    dataCopy
        .sort((a: ListItem, b: ListItem) => {
            if (a.isChecked !== b.isChecked) {
                return a.isChecked ? 1 : -1
            }

            return a.sortName.localeCompare(b.sortName)
        })

    return dataCopy
}

export default function List() {
    let { id } = useParams() as { id: string };
    id = id || '';
    const repository = useContext(DataContext)

    const [maxPositionDisplayed, setMaxPositionDisplayed] = useState(100)

    const [title, setTitle] = useState('...')
    const [data, setData] = useState([] as ListItem[])

    const [allData, setAllData] = useState([] as ListItem[])

    const [hasMoreData, setHasMoreData] = useState(false)

    const [filterDialogOpen, setFilterDialogOpen] = useState(false)

    const [dialogOpen, setDialogOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<ListItem>()

    const [filterText, setFilterText] = useState('')

    const [filterClear, setFilterClear] = useState(0)

    const dispatchLoading = useLoadingDispatch() as ({ isLoading }: { isLoading: boolean }) => any;

    const deleteItem = useCallback(() => {
        console.log('deleteItem')

        const execute = async () => {
            if (selectedItem !== null) {
                const newDataWithLoading = sortList(allData.map(d => {
                    if (d.id === selectedItem?.id) {
                        return Object.assign({}, d, { isLoading: true })
                    } else {
                        return d;
                    }
                }), filterText)

                setData(newDataWithLoading.slice(0, maxPositionDisplayed))
                setDialogOpen(false)

                await repository.getListItems().deleteItem(id, selectedItem!.id);

                const newAllData = sortList(allData.filter(d => d.id !== selectedItem?.id), null)
                const newDataWithoutItem = sortList(newAllData, filterText)

                setAllData(newAllData)
                console.log('deleteItem', 'setAllData', newAllData)
                setData(newDataWithoutItem.slice(0, maxPositionDisplayed))
            }
        }

        execute()
    }, [selectedItem, id, repository, filterText, allData, maxPositionDisplayed])

    const doFilter = useCallback( (text: string) => {
        setFilterText(text)
        console.log('filter', text);
        const sorted = sortList(allData, text)
        setData(sorted.slice(0, maxPositionDisplayed))
        setHasMoreData(sorted.length >= maxPositionDisplayed)
    }, [allData, maxPositionDisplayed])

    const setChecked = useCallback((item: ListItem, checked: boolean) => {
        const newAllData = sortList(allData.map(d => {
            if (d.id === item.id) {
                return Object.assign({}, d, { isChecked: checked }) as ListItem
            } else {
                return d;
            }
        }), null)

        setAllData(newAllData);
        console.log('setChecked', 'setAllData', newAllData);

        (async () => await repository.getListItems().updateItem(id, item.id, checked, item.name))();

        setFilterClear(filterClear + 1)
        setFilterText('')
        const sorted = sortList(newAllData, '')
        setData(sorted.slice(0, maxPositionDisplayed))
        setHasMoreData(sorted.length >= maxPositionDisplayed)
    }, [id, repository, allData, filterClear, maxPositionDisplayed])

    const onItemAdded = useCallback((newListItem: ListItem) => {
        const execute = async () => {
            let newData = sortList([newListItem, ...allData], null)
            setAllData(newData)
            console.log('onItemAdded', 'setAllData', newData);
            setData(newData.slice(0, maxPositionDisplayed))

            try {
                await repository.getListItems().saveItem(id, newListItem)
            } catch (e: any) {
                NotificationManager.error(`${e}`, `Error on saving item`)
            } finally {
                newListItem.isLoading = false;

                setAllData(newData)
                console.log('onItemAdded', 'setAllData2', newData);
                setData(newData.slice(0, maxPositionDisplayed))

                setFilterText('')
                setHasMoreData(newData.length >= maxPositionDisplayed)
            }
        }

        execute()
    }, [id, repository, allData, maxPositionDisplayed])

    const fetch = useCallback(async () => {
        dispatchLoading({ isLoading: true })

        try {
            var list = await repository.getLists().getListById(id);
            setTitle(list.name);

            const items = await repository.getListItems().getItems(id);
            const sorted = sortList(items, null)

            setAllData(sorted)
            console.log('fetch', 'setAllData', sorted)
            setData(sorted.slice(0, maxPositionDisplayed))
            setHasMoreData(sorted.length >= maxPositionDisplayed)
        }
        catch (e: any) {
            NotificationManager.error(`${e}`, `Error on loading items`)
        } finally {
            dispatchLoading({ isLoading: false })
        }
    }, [dispatchLoading, repository, id, maxPositionDisplayed])

    useEffect(() => {
        console.log('initial load data')
        fetch()
    }, [fetch, maxPositionDisplayed])

    const updateItem = useCallback(async (name: string) => {
        if (selectedItem !== null) {
            let newAllData = sortList(allData.map(d => {
                if (d.id === selectedItem?.id) {
                    return Object.assign({}, d, { name, isLoading: true }) as ListItem
                } else {
                    return d
                }
            }), null)

            setDialogOpen(false)
            setAllData(newAllData)
            setData(sortList(newAllData.slice(0, maxPositionDisplayed), filterText));

            (async () => {
                await repository.getListItems().updateItem(id, selectedItem!.id, selectedItem!.isChecked, name);

                newAllData = sortList(allData.map(d => {
                    if (d.id === selectedItem?.id) {
                        return Object.assign({}, d, { name, isLoading: false }) as ListItem
                    } else {
                        return d
                    }
                }), null)

                setAllData(newAllData)
                setData(sortList(newAllData.slice(0, maxPositionDisplayed), filterText));
            })();
        }
    }, [filterText, allData, selectedItem, maxPositionDisplayed, id, repository])

    return <>
        <MenuBar title={title} openFilter={() => setFilterDialogOpen(true)} requestRefresh={() => fetch()} />

        <ListFilter open={filterDialogOpen} handleClose={() => setFilterDialogOpen(false)} />
        <ItemDialog item={selectedItem} open={dialogOpen} onSave={(id, name) => updateItem(name)} onDelete={() => deleteItem()} onCancel={() => setDialogOpen(false)} />

        <Grid container>
            <Grid item xs={0} md={4}></Grid>

            <Grid item xs={12} md={4}>
                <FilterField filterClear={filterClear} onItemAdded={onItemAdded} onTextEntered={text => doFilter(sanitize(text))} />
            </Grid>

            <Grid item xs={0} md={4}></Grid>
            <Grid item xs={0} md={4}></Grid>

            <Grid item xs={12} md={4}>
                <Data data={data} setSelectedItem={setSelectedItem} setDialogOpen={setDialogOpen} setChecked={setChecked} hasMoreData={hasMoreData} onLoadMoreRequested={() => setMaxPositionDisplayed(1000)} />
            </Grid>
            <Grid item xs={0} md={4}></Grid>
        </Grid>
    </>;
}

interface FilterFieldArguments {
    filterClear: number,
    onItemAdded: (item: ListItem) => void,
    onTextEntered: (text: string) => void
}

function FilterField({ filterClear, onItemAdded, onTextEntered }: FilterFieldArguments) {
    const [text, setText] = useState('')

    useEffect(() => {
        if (filterClear > 0) {
            setText('')
        }
    }, [filterClear])

    const handleKeyPressed = useCallback( (key: any) => {
        if (textEnteredTimeout !== null) {
            clearTimeout(textEnteredTimeout)
        }

        if (key.keyCode === 13) {
            console.log('handleKeyPressed enter')

            const newListItem = { id: uuidv4(), name: text, isChecked: false, description: '', isLoading: true, sortName: sanitize(text) } as ListItem
            onItemAdded(newListItem)

            setText('')
        } else {
            textEnteredTimeout = setTimeout(() => onTextEntered(text), minimumFilterDelay)
        }
    }, [onItemAdded, text, onTextEntered])

    return <TextField id="standard-basic" fullWidth label="Item" variant="standard" onChange={ e => setText(e.target.value) } value={text} onKeyUp={key => handleKeyPressed(key)} />
}

interface DataArguments {
    data: ListItem[] | undefined,
    setSelectedItem: (item: ListItem) => void,
    setDialogOpen: (isOpen: boolean) => void,
    setChecked: (item: ListItem, checked: boolean) => void,
    hasMoreData: boolean,
    onLoadMoreRequested: () => void
}

function Data({data, setSelectedItem, setDialogOpen, setChecked, hasMoreData, onLoadMoreRequested}: DataArguments): any {
    return <>
        {
            data?.map(i => <ListItemRow item={i} key={i.id} isLoading={i.isLoading} onEdit={item => {
                setSelectedItem(item);
                setDialogOpen(true)
            }} onChecked={(item, checked) => setChecked(item, checked) } />)
        }

        {hasMoreData && <Paper style={{ padding: '3px', margin: '3px' }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={2} sm={1}></Grid>

                <Grid item xs={8} sm={10}>
                    <Typography style={{ textAlign: 'center' }}>
                        <Button onClick={onLoadMoreRequested} fullWidth>Load more</Button>
                    </Typography>
                </Grid>

                <Grid item xs={2} sm={1} alignContent='end' alignItems='end' style={{ textAlign: 'right' }}> </Grid>
            </Grid>
        </Paper>}
    </>
}