import React, { useCallback, useContext, useState } from 'react';

import { Button, FormControl, TextField, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import DataContext from '../contexts/DataContexts'
import { NotificationManager } from 'react-notifications';

export default function LoginDialog({ open, handleClose }: { open: boolean, handleClose: () => void }) {
    const repository = useContext(DataContext)

    const [ logging, setLogging ] = useState(false)
    const [ apiKey, setApiKey ] = useState('')

    const tryLogin = useCallback(() => {
        const run = async () => {
            setLogging(true)

            try {
                if (await repository.tryLogin(apiKey)) {
                    handleClose()
                } else {
                    NotificationManager.error('Incorrect key');
                }
            } finally {
                setLogging(false)
            }
        }

        run()
    }, [setLogging, apiKey, handleClose, repository])

    return <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        fullWidth={true}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>Login</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
            <FormControl fullWidth>
                <TextField id="apikey" label="API-KEY" variant="outlined" value={apiKey} onChange={e => setApiKey(e.target.value)} />
            </FormControl>

            {logging && <LinearProgress variant='indeterminate' style={{ marginTop: '10px' }} />}
        </DialogContent>
        <DialogActions>
            <Button onClick={tryLogin}>Login</Button>
        </DialogActions>
    </Dialog>
}